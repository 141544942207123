.service_sec{
    margin: 50px 0;
    text-align: center;
    color: tomato;
    text-align: center;
    font-size: 55px;
}




.services-section .services-content-right {
	float: right;
}
.services-section .services-content-right .services-box {
	text-align: left;
}
.services-section .services-content-center {
	text-align: center;
}
.services-section .services-box {
	margin-bottom: 25px;
	text-align: right;
}
.services-section .services-box i {
	color: #ff9c00;
	font-size: 25px;
}
.services-section .services-box h3 {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0.64px;
	margin-top: 10px;
}
.services-section .services-box p {
	color: #777;
	font-family: 'Lato', sans-serif;
	font-size: 14px;
	letter-spacing: 0.56px;
	line-height: 26px;
}

/* - Delivery Section */

.delivery-section {
	background-image: url("../images/bg2.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
    margin: 50px 0;
}
.delivery-section::before {
	background-color: rgba(0, 0, 0, 0.8);
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.delivery-section .delivery-img img {
	position: absolute;
	top: 80px;
}
.delivery-section .delivery-content {
	padding: 84px 0;
}
.delivery-section .section-header {
	margin-bottom: 30px;
}
.delivery-section .left-header {
	text-align: left;
}
.delivery-section .left-header h3 {
	color: #fff;
}
.delivery-section .left-header p {
	color: #ccc;
	padding-left: 0;
	padding-right: 50px;
}
.delivery-section .delivery-contact a.order-online {
	background-color: #ff9c00;
	border-radius: 50px;
	border: 1px solid #ff9c00;
	color: #222;
	display: inline-block;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 12px;
	padding: 13px 42px;
	letter-spacing: 1.8px;
	text-transform: uppercase;
	text-decoration: none;
	-webkit-transition: all 1s ease 0s;
	-moz-transition: all 1s ease 0s;
	-o-transition: all 1s ease 0s;
	transition: all 1s ease 0s;
}
.delivery-section .delivery-contact a.order-online:hover {
	background-color: transparent;
	color: #ff9c00;
}
.delivery-section .delivery-contact p span {
	padding: 0 35px;
}
.delivery-section .delivery-contact p {
	color: #fff;
	display: inline-block;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 0.56px;
	margin-bottom: 0;
	text-transform: uppercase;
}
.delivery-section .delivery-contact p a {
	color: #ff9c00;
	text-transform: uppercase;
	text-decoration: none;
}

@media screen and (max-width: 800px) {
	.services-section .services-content-center {
		margin-top: 120px;
	}
}

@media screen and (max-width:767px) {
	.delivery-section .delivery-img img {
		left: -65px;
	}
	.delivery-contact {
		margin: 0 auto;
		text-align: center;
	}
	.delivery-section .left-header p{
		padding: 0%;
	}
	.delivery-section .left-header {
		text-align: center;
	}
}

@media screen and (max-width: 640px) {
	.delivery-section .delivery-img img {
		left: -25px;
	}
}

@media screen and (max-width:575px) {
	.delivery-section .delivery-img img {
		left: -15px;
	}
}

@media screen and (max-width: 480px) {
	.service_sec {
		font-size: 48px;
	}
	.delivery-section .delivery-contact p{
		font-size: 11px;
	}
}