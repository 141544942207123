.register_user
{
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    width: 100%;
    height: 100vh;
    background: url("../images/parallax.jpg") no-repeat bottom;
    background-size: cover;
}

h1.heading.registertitle {
    color: tomato;
}
table.regform td {
    padding-left: 20px;
    width: 90%!important;
}

.register{
	background-color: #f2f3f7;
	max-width: 50%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	border: 1px solid #58677B;
}

table {
	width: 50%;
	margin: 0 auto;
	padding: 2%;

	border-radius: 4px;
	background-color: #f2f3f7;
	min-width: 360px;
	
}
input[type=text], [type=email], [type=password], [type=submit] {
	width: 100%;
	height: 40px;
	padding: 12px;
	border: 1px solid #cccccc;
	border-radius: 3px;
	outline: none;
	font-size: 14px;
	color: #222;
    width:96%;
}
input[type=text] {
	width: 48%;
}
input:hover {
	border-color: tomato;
}
input[type=submit] {
	background-color: tomato;
	background: tomato;
	color: #ffffff;
	font-weight: 600;
	border: none;
    width: 150px;
    margin-bottom: 10px;
}
input[type=submit]:hover {
	cursor: pointer;
}
::placeholder {
	color: #222222;
}
p {
	text-align: center;
	font-size: 14px;
	color: #969fa4;
	margin: 12px 0;
}
input[type="checkbox"] {
	vertical-align: middle;
	height: 14px;
	width: 14px;
	background-color: red;
}
.terms>label {
	font-size: 14px;
	color: #969fa4;
	margin-left: 6px;
}
/* div {
	margin: 10px 0;
} */

.inputreg{
    margin-top: 15px;
}
.terms {
    margin: 10px 0;
}
a {
	color: tomato;
	text-decoration: none;
}
.have_signup {
	margin-top: 16px;
}
.have_signup>p {
	color: tomato;
}
.have_signup>p>a {
	color: #ffffff;
	text-decoration: underline;
}
.dash {

	min-height: 2px;
	height: 2px;
	max-height: 2px;
	width: 100%;
	background-color: #D4D4D4;
}
h1 {
	text-align: center;
	font-weight: 500;
	color: #636363;
	text-align: 31px;
}
.top {
	width: 100%;
	background-color: salmon;
}

@media screen and (max-width: 640px) {
	
	.register{
		max-width: 100%;
	}
}

@media screen and (max-width: 420px) {
	table.regform {
		min-width: 90%;
	}
	.register{
		width: 90%;
	}
}