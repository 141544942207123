.footer {
	color: #fff;
	width: 100%;
	padding: 70px 0 0 0;
    background:#000;
    text-align: left;
}

.footer p {
	font-size: 15px;
	margin: 0 0 25px 0;
	color: #fff;
	text-align: left;
}

.footer a {
	color: #fff;
}

.footer-inner {
	width: 1110px;
	margin: 0 auto;
}

.one-fourth {
	width: calc(25% - 22.5px);
	float: left;
	margin: 0 30px 0 0;
}

.one-fourth ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    
    flex-direction: column;
}
.one-fourth ul li {
    width: 50%;
    margin: 3px 0 10px;
    padding-left: 20px;
    position: relative;
}

.one-half {
	width: calc(50% - 45px);
	float: left;
	margin: 0 30px 0 0;
}

.last-col {
	margin: 0;
}

.footer-inner .one-fourth:nth-child(4n) {
	margin: 0;
}

.footer h5 {
	font-size: 17px;
	margin: 0 0 25px 0;
	color: tomato;
}

.footer-bottom {
	padding: 30px 0;
	margin: 45px 0 0 0;
    border-top: #3b3b3b 1px solid;
}

.footer p.footer-message {
	width: 100%;
	text-align: center;
	font-size: 16px;
	line-height: 120%;
	margin: 0;
}

.footer-social-icons-wrapper {
	width: 100%;
	text-align: center;
	padding: 0 0 25px 0;
}

.footer-social-icons-wrapper a {
	font-size: 18px;
	margin: 0 28px 0 0;
}

.footer-social-icons-wrapper a:last-child {
	margin: 0;
}

/* 9.1 - Footer List */
.footer ul li {
	font-size: 15px;
	margin: 0 0 11px 0;
	width: 100%;
}

.footer form input[type="text"] {
    width: calc(100% - 26px);
    padding: 12px 13px 11px 13px;
    margin: 0 0 20px 0;
    border: none;
    font-size: 16px;
    color: #bbbbbb;
    border-radius: 0;
}


.footer-social svg:not(:root) {
font-size: 20px;
}

@media screen and (max-width: 1200px) {
	.footer-inner.clearfix {
		width: 95%;
		margin: 0 auto;
	}
}
@media screen and (max-width: 640px) {
	.footer p {
		font-size: 13px;
	}
	.footer ul li {
		font-size: 13px;
	}
}

@media screen and (max-width:575px) {
	.footer ul li {
		font-size: 12px;
	}
	.footer p {
		font-size: 12px;
	}
}

@media screen and (max-width:480px) {
	.one-half,
	.one-fourth {
		width: 100%;
	}
	.footer p,
	.footer ul li  {
		font-size: 14px;
	}
	
}