
   .menu {
    padding: 5rem 0;
  }
  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .underline {
    width: 6rem;
    height: 0.25rem;
    background: tomato;
    margin-left: auto;
    margin-right: auto;
  }
  
  article.menu-item p {
    text-align: left;
}

  .btn-container {
    margin-bottom: 4rem;
    display: flex;
    justify-content: center;
  }
  
  .filter-btn {
    background: transparent;
    border-color: transparent;
    font-size: 1rem;
    text-transform: capitalize;
    margin: 0 0.5rem;
    letter-spacing: 1px;
    padding: 0.375rem 0.75rem;
    color:  hsl(360, 71%, 66%);
    cursor: pointer;
    transition: all 0.3s linear;
    border-radius: 0.25rem;
    font-size: 16px;
  }
  
  .active {
    border-bottom: 2px solid;
  }
  
  .section-center {
    width: 90vw;
    margin: 0 auto;
    max-width: 1170px;
    display: grid;
    gap: 3rem 2rem;
    justify-items: center;
  }
  
  .menu-item {
    display: grid;
    gap: 1rem 2rem;
    max-width: 25rem;
  }
  
  .photo {
    object-fit: cover;
    height: 200px;
    width: 100%;
    border: 0.25rem solid hsl(360, 71%, 66%);
    border-radius: 0.25rem;
    display: block;
  }
  
  .logo {
    object-fit: cover;
    height: auto;
    width: 200px;
    border-radius: 0.25rem;  
  }
  
  .item-info header {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px dotted hsl(210, 22%, 49%);
  }
  .item-info h4 {
    margin-bottom: 0.5rem;
    color: tomato;
  }
  h4.price{
    color: #555555;
  }
  .price {
    color: #222;
  }
  .item-text {
    margin-bottom: 0;
    padding-top: 1rem;
    color: #222;
    font-size: 16px;
  }
  
  
  @media screen and (min-width: 768px) {
    .menu-item {
      grid-template-columns: 225px 1fr;
      gap: 0 1.25rem;
      max-width: 55rem;
    }
    .photo {
      height: 175px;
    }
  }
  @media screen and (min-width: 1200px) {
    .section-center {
      width: 95vw;
      grid-template-columns: 1fr 1fr;
    }
    .photo {
      height: 150px;
    }
  }

  @media screen and (max-width:575px){

    .btn-container{
      width: 90vw;
      margin: 0 auto;
    }

    .filter-btn {
      font-size: 10px;
      padding: 0;
    }
    .section-center{
      padding-top: 20px;
    }
    }
  @media screen and (max-width:420px) {
    
  }