h1.topping_title {
    color: tomato;
}

.pizza-builder-step + .pizza-builder-step {
    border-top: 1px solid rgba(34, 34, 34, 0.2);
}

.btn-more {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    display: inline-block;
    height: 40px;
    padding: 0 50px;
    border-radius: 20px;
    outline: none;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 38px;
    line-height: normal;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid #cccccc;
    background-color: transparent;
    color: #222222;
    transition: 0.3s;
}

.btn-more:hover{
    border-color: #ff0000;
    background-color: #ff0000;
    color: #ffffff;

}

.pizza-builder-order {
    border: 1px solid #ff0000;
}

.pizza-builder-order__title {
    color: tomato;
}

.pizza-builder-order__row + .pizza-builder-order__row {
    margin-top: 7px;
}

.pizza-builder-order__row-name {
    -webkit-box-flex: 1;
    flex: 1;
    font-size: 13px;
    font-weight: 300;
}

.pizza-builder-order__row-col {
    margin: 0 5px;
}

.pizza-builder-order__row-name {
    -webkit-box-flex: 1;
    flex: 1;
    font-size: 13px;
    font-weight: 300;
}

.pizza-builder-order__rows {
    display: block;
    position: relative;
    margin: 35px 0 37px;
}

.pizza-builder-order__row-price {
    font-size: 13px;
    font-weight: 300;
}

.counter .minus, .counter .plus {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
}

.uk-button:hover, .uk-button:focus, .uk-button:active {
    background-color: tomato;
    color: #fff;
    border: 1px solid tomato;
}
.uk-button:hover {
    text-decoration: none;
}

.uk-button {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 20px;
    border-radius: 2px;
    background-color: #fff;
    color: tomato;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 50px;
    text-transform: uppercase;
    transition: 0.5s;
    border: 1px solid tomato;
}

.cheese-item__price, .toppings-item__price {
    display: block;
    color: tomato;
}

.pizza-builder {
    margin-bottom: 50px!important;
}

.size-pizza-item__circle {
    border: 1px solid #eeeeee;
    background-color: #ffffff;
}

.size-pizza-item__title {
    margin-top: 30px;
    font-weight: 400;
    text-align: center;
}
 .size-pizza-item__box input:checked + .size-pizza-item__content > .size-pizza-item__circle {
    -webkit-box-shadow: 0 0 0px 5px #6dc405;
    border: 1px solid #6dc405;
    box-shadow: 0 0 0px 5px #6dc405;
}


 .pizza-builder-order {
    border: 1px solid #ff0000;
  }
.pizza-builder-order__title {
    color: #6dc405;
  }
  
.pizza-builder-order__row-title span::after {
    background-color: rgba(34, 34, 34, 0.5);
  }
  
  .pizza-builder-order__info {
    border-top: 1px solid rgba(34, 34, 34, 0.2);
    border-bottom: 1px solid rgba(34, 34, 34, 0.2);
  }
  
 .pizza-builder-order__total {
    color: #6dc405;
  }
  
   .pizza-builder-step + .pizza-builder-step {
    border-top: 1px solid rgba(34, 34, 34, 0.2);
  }
  
 .pizza-builder-step__numb {
    color: #6dc405;
  }
  
.pizza-builder-step__numb::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='65' height='15' viewBox='0 0 65 15'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23222' d='M64.078 14.463l-8.973-7.297-8.914 7.49-9.036-7.35-8.913 7.49-9.036-7.348-8.912 7.49-9.144-7.44.17-.216 8.971 7.297 8.912-7.49 9.036 7.35 8.913-7.49 9.036 7.35 8.914-7.49 9.146 7.438zm-.896-6.87L54.208.294l-8.914 7.49-9.036-7.35-8.912 7.49L18.31.575l-8.912 7.49L.254.627.424.41l8.97 7.298 8.913-7.49 9.036 7.35 8.913-7.49 9.035 7.349 8.914-7.49 9.147 7.439z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
  
  .size-pizza-item__box input {
    display: none;
  }
.size-pizza-item__box input:checked + .size-pizza-item__content > .size-pizza-item__circle {
    -webkit-box-shadow: 0 0 0px 5px #6dc405;
    border: 1px solid #6dc405;
    box-shadow: 0 0 0px 5px #6dc405;
  }
  
 .size-pizza-item__box input:checked + .size-pizza-item__content > .size-pizza-item__circle::after {
    opacity: 1;
  }
  
   .size-pizza-item__circle {
    border: 1px solid #eeeeee;
    background-color: #ffffff;
  }
  
  .size-pizza-item__circle span {
    color: #222222;
  }
  
 .cheese-item__media .counter .minus,
  .cheese-item__media .counter .plus,
   .toppings-item__media .counter .minus,
 .toppings-item__media .counter .plus {
    background-color: #6dc405;
  }
  
  @media only screen and (max-width: 1440px) {
   .uk-slidenav {
      background-color: #ffffff;
    }
  }
  
  @media only screen and (max-width: 959px) {
     .product-full-card__info-list li {
      border-bottom: 1px solid rgba(62, 60, 60, 0.15);
    }
  }

  .counter .input, .counter input {
    display: inline-block;
    width: 40px;
    height: 20px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 2px;
    background-color: transparent;
    color: #6dc405;
    font-size: 26px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    text-align: center;
    vertical-align: middle;
}


@media screen and (max-width:1024px) {
  .uk-button{
      font-size: 12px;
      padding: 0;
  }
}

@media screen and (max-width:800px) {
  .pizza-builder-order {
      margin-top: 20px;
  }

  .pizza-builder-order__btns .uk-button + .uk-button {
    width: 205px;
    margin-left: 20px;
    margin-top: 0px;
  }
}

@media screen and (max-width:420px) {
  .pizza-builder-order__btns .uk-button + .uk-button {
    margin-top: 15px;
    width: 205px;
    margin-left: 0px;
}
}