:root{
    --primary: red;
    --warning: #ffd028;
    --danger: #eb3f27;
    --success: #75fa83;
    --white: #fdfdfd;
    --dark: #181717;

}
/* 
.btn{
    /font-weight: 400;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease; 
    border: 2px solid tomato;
    padding: 6px 25px;
    border-radius: 10px;
    text-transform: uppercase;
    height: 40px;
    line-height: 1.7;
    font-size: 13px;
    transition: all .5s ease; 
} */

.btn:hover{
    transform: translate(-3px);
}

/* Button Color */
.btn--primary--solid{
    background-color: var(--primary);
    color: var(--white);
    border: none;
}
.btn--warning--solid{
    background-color: var(--warning);
    color: var(--white);
    border: none;
}
.btn--danger--solid{
    background-color: var(--danger);
    color: var(--white);
    border: none;
}
.btn--success--solid{
    background-color: var(--success);
}
.btn--primary--outline{
    background-color: transparent;
    border: 2px solid var(--primary);
    color: var(--primary);
}
.btn--warning--outline{
    background-color: transparent;
    border: 2px solid var(--warning);
    color: var(--warning);
}
.btn--danger--outline{
    background-color: transparent;
    border: 2px solid var(--danger);
    color: var(--danger);
}
.btn--success--outli{
    background-color: transparent;
    border: 2px solid var(--success);
    color: var(--success);
}

/* Button Sizes */
.btn--medium{
    font-size: 18px;
    margin-top: 10px;

}

.btn--small{
    padding: 15px 30px;
    font-size: 20px;
}
