nav{
    background:rgba(0,0,0,0.9);
    padding: 11px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    position: relative;
    z-index: 999;
}

.menu-icon{
    display: none;
}

.fas{
    cursor: pointer;
}

.menu-icon .fa-times{
    transform: rotate(360deg);
    transition: all 0.2s ease-in-out;
}

.menu-icon .fa-bars{
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
}
.logo{
    font-weight: 600;
    font-size: 25px;
    color: tomato;
}

.logo font{
    color: #fff;;
}

.menu-list{
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-list li a{
    text-decoration: none;
    color: #fff;
    font-weight: 300;
    padding: 5px 10px ;
    margin-left: 20px;
    font-size: 16px;
}

.menu-list li:last-child a{
    font-weight: 300;
    padding: 5px 10px;
    background-color: tomato;
    transition: all 0.5s ease-in-out;
    margin-left: 20px;
    border-radius: 2px;
}

.menu-list li a:hover,
.menu-list li a.active {
    background-color: #fff;
    border-radius: 2px;
    color: tomato;
    transition: all 0.5s ease-in-out;
    font-weight: bold;
}

.page-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    position: relative;
    z-index: 2;
}

.page-heading h1{
    font-size: 5rem;
    color: #fff;
    font-weight: 300;
    background-color: rgba(0,0,0,0.5);
    padding: 0px 20px;
    border-radius: 2px;
}

.close{
    opacity: 0.9!important;
}


/* Responsive */

@media screen and (max-width:800px){
    .logo{
        font-size: 20px;
    }
    .menu-list li a{
        font-size: 14px;
        margin-left: 10px;
    }
}

@media screen  and (max-width:600px){
    .menu-icon{
        display: block;
        margin-top: 15px;
        margin-right: 15px;
    }
    .menu-list li {
        margin-bottom: 10px;
    }

    .menu-list{
        flex-direction: column;
        width: 100%;
        position: absolute;
        background-color: rgb(0 0 0);
        padding: 0;
        right: 0;
        top: 62px;
        transition: all 0.5s ease-in-out;
    }
    .menu-list li{
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
    }
    .menu-list li:last-child{
        margin-bottom: 0px;
        margin-left: 0px!important;
    }

    .pickup_delivery {
        margin-top: -30px!important;
    }
    .menu-list li a,
    .menu-list li :last-child a{
        width: 100%;
        display: block;
        margin: auto;
    }
    .menu-list.close{
        right: -100%;
        transition: all 0.5s ease-in-out;

    }

    #gkHeaderMod .gk-short-menu {
        margin: 20px 0px 125px!important;
        text-align: center;
    }

    #gkHeaderMod .gk-short-menu li {
        margin: 0px 20px;
        margin-bottom: 20px;
    }
}