.classic,
.sidepizza{
    width: 1200px;
    margin: 0 auto;
}

.sidepizza{
    margin-bottom: 50px;
}

h1.heading.default {
    color: tomato;
    text-align: center;
    font-size: 55px;
    margin-bottom: 40px;
}

img.image-md {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
h1.title-v3-md{
    font-size: 20px;
    line-height: 24px;
}
p{
    color: #555;
}

.classic h5,
.sidepizza h5 {
    font-size: 18px;
}

.classic p,
.sidepizza p {
    font-size: 13px;
    text-align: left;
}

@media screen and (max-width:1200px){
    .classic,
    .sidepizza {
        width: 90%;
    }

    .classic p,
    .sidepizza p{
        text-align: center;
    }
}

@media screen and (max-width:800px) {
    .classic h5, .sidepizza h5 {
        font-size: 16px;
    }
}

@media screen and (max-width:480px) {
    h1.heading.default {
        font-size: 48px;
    }
}