.padding-100{
    padding: 50px 0;
}
.about_sec{
    width: 1200px;
    margin: 0 auto;
}
.head_title {
    margin-bottom: 30px;
    text-align: center;
}

.head_title h1 {
    color: #222;
    font-size: 30px;
}

.intro_message.mt40 p {
    color: #222;
}

.about_content {
    margin-top: 50px;
}

span.welcome {
    /* display: inline-block; */
    position: relative;
    font-size: 18px;
    color: tomato;
}

span.welcome:before {
    right: 100%;
    margin-right: 15px;
}

span.welcome:after {
    left: 100%;
    margin-left: 15px;
}

.about_service{
    width: 1200px;
    margin: 0 auto;
}
/* service */
.bg-transparent {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
    z-index: auto;
    top: 0;
    right: 0;
}

.date-blocks .block-item {
    border: 4px solid #fff;
    border-radius: 20px;
    padding: 40px;
}

.bcg {
    background-position: center center!important;
    background-repeat: no-repeat!important;
    background-attachment: fixed!important;
    background-size: cover!important;
    height: 100%!important;
    width: 100%!important;
}

.bcg.aboutbg{
    background:url('../images/about/aboutbg.jpg');
    width: 100%;
    background-color: #100909d4;
    background-blend-mode: overlay;
    background-position: fixed;
}

.block-item svg:not(:root) {
    overflow: hidden;
    font-size: 50px;
}

.block-item h2 {
    font-size: 28px;
    text-transform: uppercase;
}

.block-item svg {
    color: #fff;
}

.block-item p {
    color: #fff;
}

@media screen and (max-width:1200px) {
    .about_sec,
    .about_service{
        width: 95%;
    }
    
}

@media screen and (max-width:800px) {
    .block-item h2 {
        font-size: 18px;
    }
    .block-item p {
        font-size: 11px;
    }
}

@media screen and (max-width:767px) {
    .block-item {
        margin-bottom: 20px;
    }
}