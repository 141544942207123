.homecategory{
    display: flex;
    padding: 20px;
    justify-content: space-between;

}
.categoryitems {
    flex: 1;
    width: 100%;
    margin: 3px;
    height: 50vh;
    position: relative;
}

.categoryimage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.categoryimage img{
    width: 100%;
    background: rgba(0, 0, 0,0.6);
    opacity: 1.4;
    filter: brightness(0.4);
}

.categoryitems .info{
    position: absolute;
    top: 35%;
    text-align: center;
    left: 32%;
    text-transform: uppercase;
}

.info h1 {
    text-transform: uppercase;
    color: #fff;
}

.btn{
    border: 1px solid tomato;
    color: #fff;
    border-radius: 5px;
    background-color: tomato;
    cursor: pointer;
} 

.btn:hover{
    border: 1px solid tomato;
    background-color: #fff;
    color: tomato;
    transition: all 2s ease-in-out;
}

@media screen and (max-width: 1200px) {
    .info h1 {
        font-size: 25px;
    }
}

@media screen and (max-width: 1024px) {
    .categoryimage img {
        height: 100%;
    }
}
@media screen and (max-width:800px) {
    .categoryitems .info {
        left: 27%;
    }
    .info h1 {
        font-size: 20px;
    }
}

@media screen and (max-width:640px) {
    #gkHeaderMod h1 {
        font-size: 45px;
    }    
    .info h1 {
        font-size: 18px;
    }
}

@media screen and (max-width: 480px) {
    .homecategory {
        padding: 5px;
    }
    .categoryitems {
        height: auto;
    }
    .info h1 {
        font-size: 13px;
    }
    .categoryitems .info {
        left: 20%;
    }
}