.section-contact-form {
    background-color: #f7f7f7;
}

.contact-info-list {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 2rem;
    margin-top: 2rem;
}

.contact-info-box {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
}

.contact-info-box-icon {
    width: 7rem;
    text-align: center;
}

.contact-info-box-icon i {
    color: #003a6a;
    font-size: 3rem;
}

.contact-info-box-text {
    width: 100%;
}

.contact-info-box-text strong {
    font-family: "Nunito Sans", sans-serif;
    font-size: 17px;
    display: block;
    margin-bottom: 1rem;
    color: #222;
}

.contact-info-box-text a:hover {
    color: #003a6a;
    text-decoration: underline;
}

.contact-info-map iframe {
    width: 100%;
    height: 450px;
}

.contact-form input[type="submit"] {
    display: inline-block;
    font-weight: 700;
    background-color: tomato;
    border: 2px solid tomato;
    width: 100px;
    text-transform: uppercase;
    height: 50px;
}

.contact-form input[type="submit"]:hover {
    color: tomato;
    background-color: #fff;
}


.formfield {
    margin-bottom: 2rem;
}

.formfield label {
    font-size: 17px;
    font-weight: 500;
    color: #222;
}

.formfield small {
    color: tomato;
    font-size: 1.5rem;
    display: block;
    margin-bottom: .2rem;
}

.formfield[class*="col-"] {
    padding-top: 0;
    padding-bottom: 0;
}

.formfield input+input {
    margin-top: 1rem;
}

.formfield>input:not([type="submit"]),
.formfield>select:not([size]):not([multiple]),
.formfield>textarea {
    font-size: 1.4rem;
    min-width: 120px;
    border-radius: 0;
    background-color: #fff;
}

.formfield>input:not([type="submit"]),
.formfield>select:not([size]):not([multiple]) {
    width: 100%;
    height: 40px;
}

.formfield>input:not([type="submit"]):focus {
    border: 0;
    border-bottom: 2px solid #222;
    box-shadow: none;
}

.formfield>input[type="checkbox"] {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    min-width: 1px;
    transform: translateY(2px);
    margin-right: .5rem;
}

.formfield>textarea {
    width: 100%;
    max-width: 100%;
    min-height: 10rem;
    border:1px solid #cccccc
}

.formfield>textarea:focus {
    box-shadow: none!important;
    background-color: transparent!important;
    border-bottom: 2px solid #222!important;
}

textarea::placeholder {
    font-size: 16px;
    color: #ccc;
    padding: 12px;
}

.formfield-submit>input {
    width: auto;
    display: inline-block;
    padding: .8rem 1.7rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #fff;
    background-color: tomato;
    border: 0;
    cursor: pointer;
    text-transform: uppercase;
}

.formfield-submit>input:hover {
    background-color: tomato;
}

.form-check {
    padding-left: 0;
    margin-bottom: 1rem;
    display: flex;
}

.form-check[class*="col-"] {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
}

.form-check input[type="radio"],
.form-check input[type="checkbox"] {
    width: auto;
    display: inline-block;
    margin-right: .5rem;
}

.form-check input[type="radio"]~label,
.form-check input[type="checkbox"]~label {
    margin-bottom: 0;
    cursor: pointer;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.customradio input[type="radio"] {
    display: none;
}

.customradio label {
    position: relative;
}

.customradio label::before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #fff;
    border: .4rem solid #fff;
    border-radius: 50%;
    margin-right: 1rem;
}

.customradio input:checked~label::before {
    border-color: #003a6a;
}

.customcheckbox input[type="checkbox"] {
    display: none;
}

.customcheckbox label {
    position: relative;
}

.customcheckbox label::before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    color: tomato;
    background-color: #fff;
    margin-right: 1rem;
}

.customcheckbox label::after {
    opacity: 0;
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-size: 1.6rem;
    font-weight: 700;
    color: tomato;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(2);
    transition: .3s all ease;
}

.customcheckbox input:checked~label::after {
    opacity: 1;
    transform: scale(1);
}

.formfield.formfield-special {
    position: relative;
    margin: 1rem 0 2rem;
}

.formfield.formfield-special label {
    font-size: 1.5rem;
    position: absolute;
    top: 5px;
    left: 10px;
    margin-bottom: 0;
    transition: all .4s ease;
}

.formfield.formfield-special>input:not([type="submit"]),
.formfield.formfield-special>select:not([size]):not([multiple]),
.formfield.formfield-special>textarea {
    font-size: 1.4rem;
    min-width: 120px;
    padding-top: 2rem;
    height: 5.5rem;
    border: 1px solid rgba(17, 17, 17, 0.5);
    border-radius: 4px;
    background-color: #fff;
}

.formfield.formfield-special input[type="submit"] {
    height: 3rem;
}

.formfield.formfield-special.focussed label {
    font-size: 1.2rem;
    top: -5px;
    left: 10px;
    color: #fff;
    background-color: tomato;
    padding: 5px 2px;
}

span.timing {
    color: #222;
}

.contact-info-box-text a {
    color: #222;
}

.contact-info-box svg:not(:root) {
    overflow: hidden;
    color: tomato;
    font-size: 30px;
    font-weight: bold;
}

.contact-info-box-text span {
    font-size: 16px;
}

.contact-info {
    margin: 50px 0;
}

h1.heading.contact {
    color: tomato;
    margin-top: 15px;
}