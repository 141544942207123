section.special_section {
    margin: 0 auto;
    width: 1200px;
}

.special_sec_inner {
    margin-top: 50px;
    margin-bottom: 50px;
}

.uk-inline-clip.uk-transition-toggle.uk-light img {
    width: 100%;
    overflow: hidden;
    height: 100%;
}

.product-item__addcart {
    margin: 0 auto;
    text-align: center;
}

/* a.uk-button.uk-button-default {
    padding: 10px;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
    width: 100%!important;
    text-decoration: none;
    font-weight: bold;
} */

.product-item__addcart .uk-button-default:hover{
    border-color: #ff0000;
    background-color: #ff0000;
    color: #ffffff;
    font-weight: bold;
}
/* body.dm-light .product-item__addcart .uk-button-default:hover, body.dm-light .product-full-card__addcart .uk-button-default:hover{
    color: #fff!important;
    stroke: #fff;
} */
.product-item {
    display: block;
    position: relative;
}

.product-item__box,
body.dm-light .product-full-card__box {
    border: 1px solid #eeeeee;
}

.product-item__box {
    border-radius: 2px;
}

.product-item__intro {
    display: block;
    position: relative;
}

.product-item__not-active {
    display: block;
    position: relative;
}

.product-item__media{
    background-color: #f5f5f5;
}

.product-item__media {
    position: relative;
    height: 260px;
}

.product-item__media .uk-inline-clip {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}

.product-item__media .uk-inline-clip a {
    -webkit-box-pack: center;
    -webkit-box-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.product-item__media .uk-overlay-primary{
    background-color: rgba(245, 245, 245, 0.8);
}

.uk-position-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: max-content;
    max-width: 100%;
    box-sizing: border-box;
}

.product-item__media .uk-position-center .uk-icon{
    background-color: #ffffff;
    color: #222222;
}

.product-item__whish {
    -webkit-box-pack: center;
    -webkit-box-align: center;
}
 .product-item__whish{
    background-color: #ffffff;
    color: #222222;
    display: flex;
    position: absolute;
    bottom: 15px;
    left: 15px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
}
.product-item__tooltip {
    -webkit-box-pack: center;
    display: flex;
    position: absolute;
    right: 15px;
    bottom: 15px;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 20px;
    -webkit-box-align: center;

}

.product-item__tooltip{
    color: #cccccc;
}

.product-item__title{
    color: #222222;
}

body.dm-light .product-item__title a {
    color: #222222;
}

.product-item__desc{
    padding: 0 20px 35px;
    font-size: 14px;
    font-weight: 400;
    color: #222222;
}

.product-item__select {
    padding: 0 20px;
}

.product-item .select-box{
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    color: #222222;
    border-radius: 21px;
}

.product-item .select-box ul{
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style-type: none;
    -webkit-box-pack: justify;
}

.product-item .select-box ul li {
    -webkit-box-flex: 1;
    flex: 1;
}

.product-item .select-box{
    border-radius: 21px;
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    color: #222222;

}
.product-item .select-box + .select-box {
    margin-top: 15px;
}

.product-item .select-box ul li label {
    display: block;
    position: relative;
    max-width: 100%;
}
.product-item .select-box label {
    cursor: pointer;
}

.product-item .select-box label input[type="radio"] {
    display: none;
}

.product-item__active{
    background-color: #ffffff;
    box-shadow: 0 0 34px 6px rgb(0 0 0 / 5%);
    height: 83vh;
}

.product-item__active .title{
    color: #222222;
}
.product-item__active .title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
}

.product-item__active .row .name{
    color: #222222;
    -webkit-box-flex: 1;
    flex: 1;
    font-size: 13px;
    font-weight: 300;
}

.product-item__active .row .col {
    margin: 0 5px;
}

.counter {
    -webkit-box-align: center;
    display: inline-flex;
    align-items: center;
}

.counter .minus, 
.counter .plus{
    -webkit-box-pack: center;
    -webkit-box-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
}

.product-item__active .row .col .counter input{
    border: 1px solid #cccccc;
}

.counter .input, .counter input{
    display: inline-block;
    width: 30px;
    height: 20px;
    border-radius: 2px;
    background-color: transparent;
    color: tomato;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
}

.product-item__active .row .price {
    font-size: 13px;
    font-weight: 300;
    color: #222222;
}

.product-item__toggle.is-show,
.product-full-card__toggle.is-show {
    background-color: #ffffff;
    box-shadow: 0 0 34px 6px rgb(0 0 0 / 5%);
}

.product-item__toggle {
    padding: 15px 20px;
}

.product-item__toggle button,
.product-full-card__toggle button {
    border: 1px solid #eeeeee;
    color: #222222;
}

.product-item__toggle button {
    -webkit-box-pack: center;
    -webkit-box-align: center;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 42px;
    border-radius: 21px;
    outline: none;
    background-color: transparent;
    font-size: 13px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
}

.product-item__info {
    padding: 15px 30px 40px;
}

.product-item__price,
.product-full-card__price {
    color: #222222;
}

.product-item__price {
    margin-bottom: 35px;
    font-family: "KaushanScript";
    font-size: 18px;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
}

.product-item__price .value, 
.product-full-card__price .value {
    color: tomato;
}

.product-item__addcart .uk-button-default,
.product-full-card__addcart .uk-button-default {
    border: 1px solid #cccccc;
    color: #222222;
}

.uk-icon {
    margin: 0;
    border: none;
    border-radius: 0;
    overflow: visible;
    font: inherit;
    color: inherit;
    text-transform: none;
    padding: 0;
    background-color: transparent;
    display: inline-block;
    fill: currentcolor;
    line-height: 0;
}

.uk-button svg {
    margin-left: 10px;
}
.product-item__active .row + .row {
    margin-top: 10px;
}

@media screen and (max-width:1200px) {
    section.special_section{
        width: 95%;
    }
}

@media screen and (max-width:980px) {
    .uk-button {
        width: 150px;
    }
}

@media screen and (max-width:800px) {
    .product-item__addcart .uk-button-default{
        font-size: 10px;
        width: 120px;
    }
    
}

@media screen and (max-width:767px) {

    .product-item__addcart .uk-button-default{
        width: 150px;
        font-size: 13px;
    }
    .product-item__box {        
        margin-bottom: 20px;
        text-align: center;
    }
    
    .product-item__title {
        margin-top: 15px;
    }
}

@media screen and (max-width:575px) {
    section.special_section {
        width: 90%;
    }
    .product-item__box {
        width: 80%;
        margin: 0 auto;
    }

    .product-item {
        margin-top: 20px;
    }

}