.single_menu{
    padding: 50px;
    display: flex;
}

.singleimage{
    width: 100%;
    object-fit: cover;
}

.DetailInfo{
    flex: 1;
    padding: 0px 50px;

}

.Infotitle{
    font-weight: 200;
}

.Infodesc{
    margin: 20px 0px;

}

.price{
    font-weight: 100;
    font-size: 14px;
}

.FilterContainer{
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin: 30px 0px;
}

.Filter{
    display: flex;
    align-items: center;
}

.FilterTitle{
    font-size: 20px;
    font-weight: 200;
}

.FilterColor{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0px 5px;
    cursor: pointer;

}

.FilterColor.black{
    background-color: #000;
}

.FilterColor.darkblue{
    background-color: darkblue;
}

.FilterColor.gray{
    background-color: gray;
}

.FilterSize{
    margin-left: 10px;
    padding: 5px;
}

.AddContainer{
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-between;
}

.AmountContainer{
    display: flex;
    align-items: center;
    font-weight: 700;
}

.Amount{
    width: 30px;
    height: 30px;
    border-radius: 10px;
    border:1px solid #ccc;
    justify-content: center;
    margin: 0px 5px;
}

