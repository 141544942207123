.banner{
    background:url('../images/bg.jpg');
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
  }
  
  .banner::after{
    background:rgba(0,0,0,0.6);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .innerbanner{
    background:url('../images/bg.jpg');
    background-position: center;
    background-size: cover;
    width: 100%;
    position: relative;
    height: 30vh;
    background-color: #02000054;
    background-blend-mode: overlay;
    filter: brightness(1.5);
  }
  
  .innerbanner::after{
    background:rgba(0,0,0,0.6);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  h1.innerbanner_title {
    color: #fff;
    font-size: 30px;
    margin-top: 60px;
}

.innerbanner h1 {
    color: #fff;
    margin-top: 50px;
} 

  .custom h1 {
    background-color: transparent!important;
}

  #gkHeaderNav {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 5px;
    height: 80px;
    left: 0px;
    position: fixed;
    top: -250px;
    width: 100%;
    z-index: 10000;
    background: rgb(255, 255, 255);
    padding: 0px;
    transition: all 0.3s ease-out 0s;
}

#gkHeaderNav > div {
    position: relative;
}

#gkHeaderNav #gkLogoSmall {
    left: 50%;
    position: absolute;
    top: 0px;
    z-index: 1;
    margin: 0px 0px 0px -56px;
}

#gkLogoSmall.cssLogo {
    height: 70px;
    width: 112px;
    margin-top: 3px !important;
    /* background: url(../assets/pizzaLogo.png) center center / contain no-repeat; */
    background-position: center center;
}

#gkMainMenuLeft {
    width: 45% !important;
}

#gkMainMenuRight {
    margin-right: 10%;
    width: 45% !important;
}

#gkMobileMenu {
    display: none;
}

.gkPage, #gkMainbody .content {
    padding: 0px;
    margin: 0px auto !important;
}

#static-aside-menu-toggler {
    cursor: pointer;
}
/* logo */
#gkLogo.cssLogo {
    height: 270px;
    width: 300px;
    /* background: url(../assets/pizzaLogo.png) center center / contain no-repeat; */
    margin: 70px auto 0px;
}
/* banner */
.back {
    font-weight: bold;
    background: rgb(241, 9, 9);
    padding: 5px;
}

.custom h2 a {
    color: rgb(255, 255, 255);
    font-size: 25px;
    text-decoration: none;
}

#gkHeaderMod .btn {
    color: rgb(0, 0, 0);
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    height: 55px;
    line-height: 56px;
    text-transform: uppercase;
    background: rgb(255, 255, 255);
    padding: 0px 35px;
}

#gkHeaderMod .gk-short-menu li {
    display: inline-block;
    margin: 0px 16px;
}

#gkHeaderMod .gk-short-menu a {
    color: rgb(255, 255, 255);
}

#gkHeaderMod .gk-short-menu span {
    display: block;
    font-size: 14px;
    font-weight: 600;
}

#gkHeaderMod h1 {
    color: rgb(255, 255, 255);
    font-size: 55px;
    padding-top: 50px;
    text-align: center;
}

#gkHeaderMod h2 {
    color: rgb(255, 255, 255);
    font-size: 24px;
    float: none;
    font-weight: 300;
    position: relative;
    width: 70%;
    padding: 30px 0px 20px;
    margin: 0px auto !important;
    text-align: center;
}

p.Delivery {
    font-size: 22px;
    margin-bottom: 5px;
    color: #fff;
    text-align: center;
}

.pickup_delivery {
    margin: 0 auto;
    text-align: center;
}

#gkHeaderMod h2::before, #gkHeaderMod h2::after {
    top: 16px;
}
#gkHeaderMod h2::before {
    content: "";
    left: 0px;
    position: absolute;
    top: 15px;
    width: 100%;
    border-top: 1px solid rgb(255, 255, 255);
}
#gkHeaderMod h2::after {
    content: "";
    height: 9px;
    left: 50%;
    position: absolute;
    top: 15px;
    transform: rotate(-45deg);
    width: 9px;
    background: rgb(255, 255, 255);
    margin: -4px 0px 0px -9px;
}

#gkHeaderMod .btn {
    color: rgb(0, 0, 0);
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    height: 55px;
    line-height: 56px;
    text-transform: uppercase;
    background: rgb(255, 255, 255);
    padding: 0px 35px;
}
#gkHeaderMod .btn:active, #gkHeaderMod .btn:focus, #gkHeaderMod .btn:hover {
    color: rgb(255, 255, 255);
    background: rgb(0, 0, 0);
}
#gkHeaderMod .gk-short-menu {
    margin: 50px 0px 150px;
}

.pickup_delivery svg:not(:root) {
    overflow: hidden;
    font-size: 40px;
    margin-left: 10px;
    margin-right: 25px;
}

.connect {
    border: 2px solid #000;
    border-radius: 50%;
    padding: 7px;
}

span.connect:hover ,
span.connect.active{
    border: 2px solid #fff;
}

