.boxchef.parallax {
    background-attachment: fixed!important
    ;
    background:url('../images/bg1.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    color: #aeaeae;
    background-color: #000000b5;
    background-blend-mode: overlay;
    margin: -60px 0!important;
    overflow: hidden;
    padding: 160px 0!important;
}

.gkNspPM-GridNews figcaption > div {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 24px;
    position: absolute;
    top: 50%;
    width: 100%;
}

h1.heading.chefspecial {
    text-align: center;
    color: #fff;
    padding: 20px 0;
}

.boxchef {
    line-height: 1.6;
    width: 100%;
}

#gkBottom4 .boxchef{
    float: left;
}

.boxchef.parallax .header, .boxchef.parallax .header>span, .boxchef.parallax .header small {
    color: #fff;
}

#gkBottom4{
    padding: 60px 0;
    background: #fff;
    overflow: hidden;
}
.bigtitle .header, .bigtitle>header h1 {
    display: inline-block;
    font-size: 60px;
    font-weight: 400;
    line-height: 1.1;
    margin: 0 160px;
    position: relative;
    text-align: center;
    text-transform: none;
    z-index: 2;
}

.gkNspPM-GridNews[data-cols="2"] > figure {
    width: 50%;
}

.gkNspPM-GridNews > figure {
    box-sizing: border-box;
    float: left;
    margin: 0;
    overflow: hidden;
    position: relative;
}

.gkNspPM-GridNews figure > a {
    float: left;
    overflow: hidden;
    width: 50%;
}

.gkNspPM-GridNews figure > a > img {
    display: block;
    transition: all .3s ease-out;
    width: 100%;
    height: 250px;
}

.gkNspPM-GridNews figcaption {
    background: #fff;
    box-sizing: border-box;
    display: block;
    /* height: 100%; */
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    transform: translate3d(0, 0, 0);
    width: 50%;
    height: 250px;
}

.gkNspPM-GridNews figcaption:before {
    border: 12px solid #fff;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    left: -24px;
    position: absolute;
    top: 65px;
}

.gkNspPM-GridNews h3 {
    font-size: 22px;
    font-weight: 400;
}

.gkNspPM-GridNews time {
    color: #999;
    font-size: 14px;
    font-weight: 500;
}

.gkNspPM-GridNews hr.separator {
    margin: 25px 0 0 0!important;
    padding: 0 0 25px 0!important;
}

.gkNspPM-GridNews figcaption p {
    color: #444!important;
    font-size: 14px;
    margin: 0;
    padding-left: 8px;
    padding-right: 8px;
}


@media screen and (max-width: 800px) {
    .gkNspPM-GridNews hr.separator {
        margin: 7px 0 0 0!important;
        padding: 0 0 15px 0!important;
    }

    .gkNspPM-GridNews figcaption p {
        font-size: 12px;
        padding: 0!important;
        margin-top: 5px;
    }
}

@media screen and (max-width:575px) {
    
    .gkNspPM-GridNews[data-cols="2"] > figure {
        width: 100%;
        margin-bottom: 20px;
    }
    
}